import {basic} from '@/config';
import fetch3 from '../../fetch3';

// 比赛列表
const list = async <T>(data: object) =>
  await fetch3<T>(`${basic['mainlandUrl']}/api/getAllImageByTypes`, 'POST-JSON', {
    ...data
  });

export default {
  list
};
