
import {reactive} from 'vue';
import {Options, Vue} from 'vue-class-component';

import {ElMessage, ElSwitch, ElDivider, ElInput} from 'element-plus';
import {App, Server} from '@/services';
import {ISportsInfoForm, SportsInformation, ISportsInfo} from '@/model/sportsInformation';
import Upload from '@/common/web/upload/index.vue';
import {basic} from '@/config';

@Options({
  components: {
    ElInput,
    ElDivider,
    ElSwitch,
    Upload
  }
})
export default class BasicSet extends Vue {
  userinfo = App.getUserinfo();
  basic = basic;
  submitLoading = false;
  rules = {
    // float_window_fb_text: [{validator: SportsInformation.validateText, trigger: 'blur'}],
    // float_window_line_text: [{validator: SportsInformation.validateText, trigger: 'blur'}]
  };
  agentInfo: any = {};
  isLoading = false;
  modelRef = reactive<ISportsInfoForm>({
    templateActive: -1,
    web_title: '',
    icon: [],
    href: '',
    float_window_line_status: 0,
    float_window_line_text: '',
    float_window_line_color: '',
    float_window_line_href: '',
    float_window_fb_status: 0,
    float_window_fb_text: '',
    float_window_fb_color: '',
    float_window_fb_href: ''
  });
  templateList: any[] = [];
  created() {
    this.initPage();
  }
  async initPage() {
    this.isLoading = true;
    await this.getTempaleList();
    await this.getInfo();
    this.isLoading = false;
  }
  beforeAvatarUpload(file: any) {
    const isJPG = file.type === 'image/jpeg' || 'image/png';
    const isLt = file.size < 1024 * 500;

    if (!isJPG) {
      ElMessage('上传图片只能是 JPG 和 PNG 格式!');
    }
    if (!isLt) {
      ElMessage('上传图片大小不能超过 500k!');
    }
    return isJPG && isLt;
  }
  async getTempaleList() {
    const res = await Server.Web.SportsInformation.getAllImageList<any>();
    if (!(res instanceof Error)) {
      this.templateList = res.map((item: any) => {
        return {
          ...item,
          name: `/${item.name}`
        };
      });
    }
  }
  async getInfo() {
    const res = await Server.Web.SportsInformation.getWebSiteInfoByAgentId<ISportsInfo>({
      agent_id: this.userinfo?.userId
    });
    if (!(res instanceof Error)) {
      this.agentInfo = res;
      const templateActive = res.float_window?.icon
        ? this.templateList.findIndex(item => item.name === res.float_window.icon)
        : -1;
      this.modelRef = {
        icon:
          res.float_window?.icon && templateActive < 0
            ? [
                {
                  uid: res.float_window.icon,
                  name: res.float_window.icon,
                  status: 'done',
                  url: `${res.cdn}${res.float_window.icon}`
                }
              ]
            : [],
        templateActive,
        href: res.float_window?.href,
        float_window_line_status: res.float_window?.line?.status,
        float_window_line_text: res.float_window?.line?.text,
        float_window_line_color: res.float_window?.line?.color,
        float_window_line_href: res.float_window?.line?.href,
        float_window_fb_status: res.float_window?.fb?.status,
        float_window_fb_text: res.float_window?.fb?.text,
        float_window_fb_color: res.float_window?.fb?.color,
        float_window_fb_href: res.float_window?.fb?.href
      };
    }
  }
  handleSubmit() {
    const form = this.$refs.formRef as any;
    const {modelRef} = this;
    this.submitLoading = true;
    form.validate(async (v: any) => {
      if (v) {
        const getFile = (item: any) => {
          return item ? item?.response?.data?.path ?? item?.name : '';
        };
        let icon = undefined;
        if (modelRef.icon && modelRef.icon.length) {
          icon = modelRef.icon.map(getFile)[0];
        } else if (modelRef.templateActive || modelRef.templateActive === 0) {
          icon = this.templateList[modelRef.templateActive].name;
        }
        const res = await Server.Web.SportsInformation.setWebSiteInfo<any>({
          logo: this.agentInfo.logo,
          domain: this.agentInfo.domain,
          web_title: this.agentInfo.web_title,
          id: this.agentInfo.id,
          agent_id: this.userinfo?.userId,
          float_window: {
            icon,
            href: modelRef.href,
            line: {
              status: modelRef.float_window_line_status,
              text: modelRef.float_window_line_text,
              color: modelRef.float_window_line_color,
              href: modelRef.float_window_line_href
            },
            fb: {
              status: modelRef.float_window_fb_status,
              text: modelRef.float_window_fb_text,
              color: modelRef.float_window_fb_color,
              href: modelRef.float_window_fb_href
            }
          }
        });
        if (!(res instanceof Error)) {
          ElMessage(this.$t('operate_success'));
          this.submitLoading = false;
        } else {
          ElMessage.error(res.message);
        }
      }
      this.submitLoading = false;
    });
  }
  resetFields() {
    this.modelRef = {
      templateActive: -1,
      web_title: '',
      icon: [],
      href: '',
      float_window_line_status: 0,
      float_window_line_text: '',
      float_window_line_color: '',
      float_window_line_href: '',
      float_window_fb_status: 0,
      float_window_fb_text: '',
      float_window_fb_color: '',
      float_window_fb_href: ''
    };
  }
  clickTemplate(index: number) {
    this.modelRef.templateActive = index;
  }
}
