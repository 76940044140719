
import {reactive} from 'vue';
import {Options, Vue} from 'vue-class-component';

import {ElMessage, ElInput} from 'element-plus';
import {App, Server} from '@/services';
import format from '@/services/format';
import {ISportsInfoForm, SportsInformation, ISportsInfo} from '@/model/sportsInformation';
import Upload from '@/common/web/upload/index.vue';
import {basic} from '@/config';

@Options({
  components: {
    ElInput,
    Upload
  }
})
export default class BasicSet extends Vue {
  userinfo = App.getUserinfo();
  basic = basic;
  submitLoading = false;
  rules = {
    web_title: [
      {
        min: 4,
        max: 16,
        message: '字符长度只能是4~16位'
      }
    ]
  };
  agentInfo: any = {};
  isLoading = false;
  modelRef = reactive<ISportsInfoForm>({
    logo: [],
    web_title: ''
  });
  created() {
    this.getInfo();
  }
  beforeAvatarUpload(file: any) {
    const isJPG = file.type === 'image/jpeg' || 'image/png';
    const isLt = file.size < 1024 * 500;

    if (!isJPG) {
      ElMessage('上传图片只能是 JPG 和 PNG 格式!');
    }
    if (!isLt) {
      ElMessage('上传图片大小不能超过 500k!');
    }
    return isJPG && isLt;
  }
  async getInfo() {
    this.isLoading = true;
    const res = await Server.Web.SportsInformation.getWebSiteInfoByAgentId<ISportsInfo>({
      agent_id: this.userinfo?.userId
    });
    if (!(res instanceof Error)) {
      this.agentInfo = res;
      this.modelRef = {
        logo: res.logo
          ? [
              {
                uid: res.logo,
                name: res.logo,
                status: 'done',
                url: `${res.cdn}${res.logo}`
              }
            ]
          : [],
        web_title: res.web_title
      };
      this.isLoading = false;
    }
  }
  handleSubmit() {
    const form = this.$refs.formRef as any;
    const {modelRef} = this;
    this.submitLoading = true;
    form.validate(async (v: any) => {
      if (v) {
        const getFile = (item: any) => {
          return item ? item?.response?.data?.path ?? item?.name : '';
        };
        const res = await Server.Web.SportsInformation.setWebSiteInfo<any>({
          ...modelRef,
          logo: modelRef.logo.map(getFile)[0],
          domain: this.agentInfo.domain,
          id: this.agentInfo.id,
          agent_id: this.userinfo?.userId
        });
        if (!(res instanceof Error)) {
          ElMessage(this.$t('operate_success'));
          this.submitLoading = false;
        } else {
          ElMessage.error(res.message);
        }
      }
      this.submitLoading = false;
    });
  }
  resetFields() {
    this.modelRef = {
      logo: [],
      web_title: ''
    };
  }
  copy(val: string) {
    format.copyTextToClipboard(val);
    ElMessage(this.$t('ui_successful_copy'));
  }
}
