
import {Options, Vue} from 'vue-class-component';
import BasicSet from './basicSet.vue';
import AdvertisingSet from './advertisingSet.vue';
import FloatWindow from './floatWindow.vue';
// import {Watch} from 'vue-property-decorator';
// import {ElMessage, ElTabs, ElTabPane} from 'element-plus';
// import {App, Format, Server} from '@/services';
// import format from '@/services/format';
// import {IMatchResult, IMatch, INewsResult, INews} from '@/model/sportsInfomation';

@Options({
  components: {
    BasicSet,
    AdvertisingSet,
    FloatWindow
    // ElTabs,
    // ElTabPane
  }
})
export default class Login extends Vue {
  tabs: any[] = [];
  // userinfo = App.getUserinfo();
  checkIndex = 0;
  // 直播链接的分页数据
  // withPage = 1;
  // withTotal = 0;
  // pageSize = 50;
  // matchList: Array<IMatch> = [];
  // newsList: Array<INews> = [];
  // isLoading = false;
  // searchParams = {
  //   times: null
  // };

  // @Watch('checkIndex')
  // onCheckIndexChange(val: number) {
  //   console.log('onCheckIndexChange val: ', val);
  //   this.resetList();
  //   this.searchParams = {
  //     times: null
  //   };
  //   this.$router.push({
  //     path: this.$route.path,
  //     query: {
  //       ...this.$route.query,
  //       ci: val
  //     }
  //   });
  //   switch (val) {
  //     case 1:
  //       this.getMatch();
  //       break;
  //     case 2:
  //       this.getNews();
  //       break;
  //   }
  // }

  created() {
    this.tabs = [
      {
        component: 'BasicSet',
        name: this.$t('sportsInfo_tab_1')
      },
      {
        component: 'AdvertisingSet',
        name: this.$t('sportsInfo_tab_2')
      },
      {
        component: 'FloatWindow',
        name: this.$t('sportsInfo_tab_3')
      }
      // {
      //   component: 'BasicSet',
      //   name: this.$t('sportsInfo_tab_4')
      // }
    ];
    const checkIndex = this.$route.query.ci ?? 0;
    this.checkIndex = +checkIndex;
    // this.withPages(1);
  }
  changeTab(index: number) {
    this.$router.push({
      path: '/sportsInformation',
      query: {
        ci: index
      }
    });
  }
  // resetList() {
  //   this.withPage = 1;
  //   this.withTotal = 0;
  //   this.matchList = [];
  //   this.newsList = [];
  // }
  // changePicker() {
  //   this.resetList();
  //   this.withPages(1);
  // }
  // withPages(e: number) {
  //   this.withPage = e;
  //   const {checkIndex} = this;
  //   switch (checkIndex) {
  //     case 1:
  //       this.getMatch();
  //       break;
  //     case 2:
  //       this.getNews();
  //       break;
  //   }
  // }
  // async getMatch() {
  //   this.isLoading = true;
  //   const res = await Server.Web.SportsInformation.matchList<IMatchResult>({
  //     page: this.withPage,
  //     size: this.pageSize
  //   });
  //   if (!(res instanceof Error)) {
  //     this.matchList = res.data;
  //     this.withTotal = res.total;
  //
  //     this.isLoading = false;
  //   }
  // }
  // async getNews() {
  //   this.isLoading = true;
  //   const times: any = this.searchParams.times;
  //   const date = times
  //     ? {
  //         start: Format.TimeHandle.format(times[0], 'YYYY-MM-DD'),
  //         end: Format.TimeHandle.format(times[1], 'YYYY-MM-DD')
  //       }
  //     : {};
  //   const res = await Server.Web.SportsInformation.newsList<INewsResult>({
  //     page: this.withPage,
  //     size: this.pageSize,
  //     ...date
  //   });
  //   if (!(res instanceof Error)) {
  //     this.newsList = res.data;
  //     this.withTotal = res.total;
  //
  //     this.isLoading = false;
  //   }
  // }
  // getLiveTitle(item: IMatch) {
  //   return `${item?.home.name} VS ${item?.away.name} (${this.$t(
  //     'game_time'
  //   )}：${Format.formatYMDHMS(new Date(item.estimate * 1000))})`;
  // }
  // getHost() {
  //   // 由于不同的语言的918地址前缀不一样  所以我们这里用正则处理一下
  //   const lang: any = {
  //     vn: 'vi.',
  //     en: 'en.',
  //     th: ''
  //   };
  //   const replace = lang[this.$i18n.locale] ?? '';
  //   const cacheUserInfo = JSON.parse(localStorage.getItem('user_info') || '{}');
  //   // 没有hosts就使用918链接
  //   const hosts = cacheUserInfo?.hosts?.length ? cacheUserInfo.hosts[0] : 'aia918.com';
  //   const url = `http://${replace}${hosts}`;
  //   return url;
  // }
  // copyMatch(item: IMatch, index: number) {
  //   const cacheUserInfo = JSON.parse(localStorage.getItem('user_info') || '{}');
  //   const link = `${this.getLiveTitle(item)} ${this.$t(
  //     'view_game'
  //   )} ${this.getHost()}/match/detail/${item.id}?t=${index}&userId=${
  //     this.userinfo?.userId
  //   }&platformCode=${cacheUserInfo.platform_code}`;
  //   this.copy(link);
  // }
  // copyNews(item: INews) {
  //   const link = `${item.title} ${this.getHost()}/news/detail/${item.id}`;
  //   this.copy(link);
  // }
  // copy(val: string) {
  //   format.copyTextToClipboard(val);
  //   ElMessage(this.$t('ui_successful_copy'));
  // }
}
