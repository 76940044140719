<template>
  <div class="main webstyles">
    <el-card shadow="never" class="cus-card">
      <el-row>
        <div class="form-title">
          <el-radio-group v-model="checkIndex">
            <el-radio-button v-for="(item, index) in navList" :label="index" :key="index">{{
              $t(item.text)
            }}</el-radio-button>
          </el-radio-group>
        </div>
        <el-col :span="24">
          <el-tabs type="border-card" v-loading="isLoading">
            <div class="listBox">
              <div v-for="item in list" class="item" :key="item.id">
                <div
                  class="pic"
                  :style="{backgroundImage: `url(${item.type.domain + item.name})`}"
                  alt=""
                />
                <div class="ctrlBar">
                  <img
                    class="download focus"
                    @click="tapMatch(item)"
                    src="@/assets/web/image/picMaterial/download.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </el-tabs>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';

import {Watch} from 'vue-property-decorator';
import {ElMessage, ElTabs, ElTabPane} from 'element-plus';
import {App} from '@/services';
import Server from '@/services/server/web/picMaterial';
import {IItem} from '@/model/picMateial';

@Options({
  components: {
    ElTabs,
    ElTabPane
  }
})
export default class Login extends Vue {
  userinfo = App.getUserinfo();
  checkIndex = 0;
  navList = [
    {
      text: 'btn_all',
      id: '110,112,113,114,115,116,117'
    },
    {
      text: 'ui_discount',
      id: '110'
    },
    {
      text: 'ui_sport',
      id: '112'
    },
    {
      text: 'ui_gaming',
      id: '117'
    },
    {
      text: 'ui_chess_web',
      id: '115'
    },
    {
      text: 'ui_slot_machine',
      id: '114'
    },
    {
      text: 'ui_lottery_ticket',
      id: '116'
    },
    {
      text: 'ui_real_person',
      id: '113'
    }
  ];
  list: IItem[] = [];
  isLoading = false;

  @Watch('checkIndex')
  onCheckIndexChange(val: number) {
    this.resetList();
    this.$router.push({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        ci: val
      }
    });
    this.getList();
  }

  created() {
    this.checkIndex = Number(this.$route.query.ci as string) || 0;
    if (!this.checkIndex) this.getList();
  }

  resetList() {
    this.list = [];
  }

  async getList() {
    this.isLoading = true;
    const res = await Server.list<IItem[]>({
      type_ids: this.navList[this.checkIndex].id
    });
    if (res instanceof Error) {
      ElMessage(res.message);
    } else {
      this.list = [...this.list, ...res];
    }
    this.isLoading = false;
  }

  tapMatch(item: IItem) {
    const req = new XMLHttpRequest();
    req.open('GET', 'http://craw248000.com/' + item.name, true);
    req.responseType = 'blob';
    req.onload = () => {
      const url = window.URL.createObjectURL(req.response);
      const a = document.createElement('a');
      a.href = url;
      a.download = this.$t('promotional_picture_materials');
      a.click();
    };
    req.send();
  }
}
</script>
<style lang="scss" scoped>
@import '../../../common/styles/element.scss';
/deep/ .el-tabs--border-card > .el-tabs__content {
  padding: 0;
}
.form-title {
  margin-bottom: 30px;
}
.listBox {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px 20px;
}
.item {
  margin: 20px 10px 0;
  width: 150px;
  height: 210px;
  border-radius: 6px;
  overflow: hidden;
  background: #fff;
  position: relative;
  .pic {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .ctrlBar {
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 42px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    text-align: right;
    .download {
      display: inline-block;
      margin: 8px 12px 0;
      width: 24px;
      height: 24px;

      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
}
</style>
