
import {Options, Vue} from 'vue-class-component';

import {Watch} from 'vue-property-decorator';
import {ElMessage, ElTabs, ElTabPane} from 'element-plus';
import {App} from '@/services';
import Server from '@/services/server/web/picMaterial';
import {IItem} from '@/model/picMateial';

@Options({
  components: {
    ElTabs,
    ElTabPane
  }
})
export default class Login extends Vue {
  userinfo = App.getUserinfo();
  checkIndex = 0;
  navList = [
    {
      text: 'btn_all',
      id: '110,112,113,114,115,116,117'
    },
    {
      text: 'ui_discount',
      id: '110'
    },
    {
      text: 'ui_sport',
      id: '112'
    },
    {
      text: 'ui_gaming',
      id: '117'
    },
    {
      text: 'ui_chess_web',
      id: '115'
    },
    {
      text: 'ui_slot_machine',
      id: '114'
    },
    {
      text: 'ui_lottery_ticket',
      id: '116'
    },
    {
      text: 'ui_real_person',
      id: '113'
    }
  ];
  list: IItem[] = [];
  isLoading = false;

  @Watch('checkIndex')
  onCheckIndexChange(val: number) {
    this.resetList();
    this.$router.push({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        ci: val
      }
    });
    this.getList();
  }

  created() {
    this.checkIndex = Number(this.$route.query.ci as string) || 0;
    if (!this.checkIndex) this.getList();
  }

  resetList() {
    this.list = [];
  }

  async getList() {
    this.isLoading = true;
    const res = await Server.list<IItem[]>({
      type_ids: this.navList[this.checkIndex].id
    });
    if (res instanceof Error) {
      ElMessage(res.message);
    } else {
      this.list = [...this.list, ...res];
    }
    this.isLoading = false;
  }

  tapMatch(item: IItem) {
    const req = new XMLHttpRequest();
    req.open('GET', 'http://craw248000.com/' + item.name, true);
    req.responseType = 'blob';
    req.onload = () => {
      const url = window.URL.createObjectURL(req.response);
      const a = document.createElement('a');
      a.href = url;
      a.download = this.$t('promotional_picture_materials');
      a.click();
    };
    req.send();
  }
}
