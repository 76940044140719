<template>
  <div class="main webstyles">
    <el-card shadow="never" class="cus-card">
      <el-row>
        <div class="form-title">
          <el-radio-group v-model="checkIndex" @change="changeTab">
            <!--            注释的为原本新闻部分-->
            <!--            <el-radio-button :label="1">{{ $t('sportsInfo_match') }}</el-radio-button>-->
            <!--            <el-radio-button :label="2">{{ $t('sportsInfo_sportNews') }}</el-radio-button>-->
            <el-radio-button v-for="(item, index) in tabs" :key="`tab-${index}`" :label="index">{{
              item.name
            }}</el-radio-button>
          </el-radio-group>
        </div>
        <el-col :span="24">
          <el-tabs type="border-card">
            <component :is="tabs[checkIndex]?.component"></component>
            <!--            <template v-if="checkIndex === 1">-->
            <!--              <div class="input-box active" v-for="item in matchList" :key="item.id">-->
            <!--                <div class="ipt">{{ getLiveTitle(item) }}</div>-->
            <!--                <div class="btns">-->
            <!--                  <el-button type="text" @click="copyMatch(item, 0)">{{-->
            <!--                    $t('btn_copy')-->
            <!--                  }}</el-button>-->
            <!--                  <el-button type="text" @click="copyMatch(item, 4)">{{-->
            <!--                    $t('sportsInfo_started')-->
            <!--                  }}</el-button>-->
            <!--                  <el-button type="text" @click="copyMatch(item, 3)">{{-->
            <!--                    $t('sportsInfo_h2h')-->
            <!--                  }}</el-button>-->
            <!--                  <el-button type="text" @click="copyMatch(item, 2)">{{-->
            <!--                    $t('sportsInfo_odds')-->
            <!--                  }}</el-button>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </template>-->
            <!--            <tempalte v-else-if="checkIndex === 2">-->
            <!--              <div class="el-form-item__content u_w&#45;&#45;100p">-->
            <!--                <el-date-picker-->
            <!--                  v-model="searchParams.times"-->
            <!--                  type="daterange"-->
            <!--                  :start-placeholder="$t('newsManagement__start')"-->
            <!--                  :end-placeholder="$t('newsManagement__end')"-->
            <!--                  @change="changePicker"-->
            <!--                >-->
            <!--                </el-date-picker>-->
            <!--              </div>-->
            <!--              <div class="input-box active" v-for="item in newsList" :key="item.atdclid">-->
            <!--                <div-->
            <!--                  v-if="item.cover.length"-->
            <!--                  class="cover"-->
            <!--                  :style="{backgroundImage: `url(${item.cover[0]})`}"-->
            <!--                />-->
            <!--                <div class="ipt">{{ item.title }}</div>-->
            <!--                <div class="btns">-->
            <!--                  <el-button type="text" @click="copyNews(item, 0)">{{ $t('btn_copy') }}</el-button>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </tempalte>-->
            <!--            <el-pagination-->
            <!--              background-->
            <!--              layout="prev, pager, next"-->
            <!--              @current-change="withPages"-->
            <!--              :total="withTotal"-->
            <!--              :page-size="pageSize"-->
            <!--            >-->
            <!--            </el-pagination>-->
          </el-tabs>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import BasicSet from './basicSet.vue';
import AdvertisingSet from './advertisingSet.vue';
import FloatWindow from './floatWindow.vue';
// import {Watch} from 'vue-property-decorator';
// import {ElMessage, ElTabs, ElTabPane} from 'element-plus';
// import {App, Format, Server} from '@/services';
// import format from '@/services/format';
// import {IMatchResult, IMatch, INewsResult, INews} from '@/model/sportsInfomation';

@Options({
  components: {
    BasicSet,
    AdvertisingSet,
    FloatWindow
    // ElTabs,
    // ElTabPane
  }
})
export default class Login extends Vue {
  tabs: any[] = [];
  // userinfo = App.getUserinfo();
  checkIndex = 0;
  // 直播链接的分页数据
  // withPage = 1;
  // withTotal = 0;
  // pageSize = 50;
  // matchList: Array<IMatch> = [];
  // newsList: Array<INews> = [];
  // isLoading = false;
  // searchParams = {
  //   times: null
  // };

  // @Watch('checkIndex')
  // onCheckIndexChange(val: number) {
  //   console.log('onCheckIndexChange val: ', val);
  //   this.resetList();
  //   this.searchParams = {
  //     times: null
  //   };
  //   this.$router.push({
  //     path: this.$route.path,
  //     query: {
  //       ...this.$route.query,
  //       ci: val
  //     }
  //   });
  //   switch (val) {
  //     case 1:
  //       this.getMatch();
  //       break;
  //     case 2:
  //       this.getNews();
  //       break;
  //   }
  // }

  created() {
    this.tabs = [
      {
        component: 'BasicSet',
        name: this.$t('sportsInfo_tab_1')
      },
      {
        component: 'AdvertisingSet',
        name: this.$t('sportsInfo_tab_2')
      },
      {
        component: 'FloatWindow',
        name: this.$t('sportsInfo_tab_3')
      }
      // {
      //   component: 'BasicSet',
      //   name: this.$t('sportsInfo_tab_4')
      // }
    ];
    const checkIndex = this.$route.query.ci ?? 0;
    this.checkIndex = +checkIndex;
    // this.withPages(1);
  }
  changeTab(index: number) {
    this.$router.push({
      path: '/sportsInformation',
      query: {
        ci: index
      }
    });
  }
  // resetList() {
  //   this.withPage = 1;
  //   this.withTotal = 0;
  //   this.matchList = [];
  //   this.newsList = [];
  // }
  // changePicker() {
  //   this.resetList();
  //   this.withPages(1);
  // }
  // withPages(e: number) {
  //   this.withPage = e;
  //   const {checkIndex} = this;
  //   switch (checkIndex) {
  //     case 1:
  //       this.getMatch();
  //       break;
  //     case 2:
  //       this.getNews();
  //       break;
  //   }
  // }
  // async getMatch() {
  //   this.isLoading = true;
  //   const res = await Server.Web.SportsInformation.matchList<IMatchResult>({
  //     page: this.withPage,
  //     size: this.pageSize
  //   });
  //   if (!(res instanceof Error)) {
  //     this.matchList = res.data;
  //     this.withTotal = res.total;
  //
  //     this.isLoading = false;
  //   }
  // }
  // async getNews() {
  //   this.isLoading = true;
  //   const times: any = this.searchParams.times;
  //   const date = times
  //     ? {
  //         start: Format.TimeHandle.format(times[0], 'YYYY-MM-DD'),
  //         end: Format.TimeHandle.format(times[1], 'YYYY-MM-DD')
  //       }
  //     : {};
  //   const res = await Server.Web.SportsInformation.newsList<INewsResult>({
  //     page: this.withPage,
  //     size: this.pageSize,
  //     ...date
  //   });
  //   if (!(res instanceof Error)) {
  //     this.newsList = res.data;
  //     this.withTotal = res.total;
  //
  //     this.isLoading = false;
  //   }
  // }
  // getLiveTitle(item: IMatch) {
  //   return `${item?.home.name} VS ${item?.away.name} (${this.$t(
  //     'game_time'
  //   )}：${Format.formatYMDHMS(new Date(item.estimate * 1000))})`;
  // }
  // getHost() {
  //   // 由于不同的语言的918地址前缀不一样  所以我们这里用正则处理一下
  //   const lang: any = {
  //     vn: 'vi.',
  //     en: 'en.',
  //     th: ''
  //   };
  //   const replace = lang[this.$i18n.locale] ?? '';
  //   const cacheUserInfo = JSON.parse(localStorage.getItem('user_info') || '{}');
  //   // 没有hosts就使用918链接
  //   const hosts = cacheUserInfo?.hosts?.length ? cacheUserInfo.hosts[0] : 'aia918.com';
  //   const url = `http://${replace}${hosts}`;
  //   return url;
  // }
  // copyMatch(item: IMatch, index: number) {
  //   const cacheUserInfo = JSON.parse(localStorage.getItem('user_info') || '{}');
  //   const link = `${this.getLiveTitle(item)} ${this.$t(
  //     'view_game'
  //   )} ${this.getHost()}/match/detail/${item.id}?t=${index}&userId=${
  //     this.userinfo?.userId
  //   }&platformCode=${cacheUserInfo.platform_code}`;
  //   this.copy(link);
  // }
  // copyNews(item: INews) {
  //   const link = `${item.title} ${this.getHost()}/news/detail/${item.id}`;
  //   this.copy(link);
  // }
  // copy(val: string) {
  //   format.copyTextToClipboard(val);
  //   ElMessage(this.$t('ui_successful_copy'));
  // }
}
</script>
<style lang="scss" scoped>
@import '../../../common/styles/element.scss';
/deep/ .el-pagination {
  margin-left: -5px;
  padding: 12px 0;
}
.form-title {
  margin-bottom: 30px;
}

.input-box {
  border-radius: 8px;
  margin: 20px 0;
  border: 1px solid #eee;
  min-height: 32px;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
}

.cover {
  width: 96px;
  height: 64px;
  background-size: cover;
  background-position: center;
  background-color: #eee;
}

.ipt {
  margin: 0 12px;
  font-size: 14px;
  flex: 1;
  color: #666;
  background: transparent;
}
.btns {
  padding-right: 10px;
}
</style>
