<template>
  <div class="wrap">
    <div class="formBox">
      <el-form :model="modelRef" :rules="rules" ref="formRef" label-position="right">
        <!--        <el-form-item :label="$t('sportsInfo_website')">-->
        <!--          <el-input-->
        <!--            v-model="modelRef.user_account"-->
        <!--            :placeholder="$t('ui_input')"-->
        <!--            disabled-->
        <!--          >-->
        <!--            <template #append>-->
        <!--              <el-button-->
        <!--                icon="el-icon-document-copy"-->
        <!--                @click="copy(modelRef.user_account)"-->
        <!--              />-->
        <!--            </template>-->
        <!--          </el-input>-->
        <!--        </el-form-item>-->
        <el-form-item :label="$t('sportsInfo_website')">
          <a href="http://www.baidu.com" target="_blank">{{ agentInfo.domain }}</a>
          <el-button
            class="margin_left"
            size="mini"
            type="primary"
            icon="el-icon-document-copy"
            @click="copy(agentInfo.domain)"
            >{{ $t('ui_copy_link') }}</el-button
          >
        </el-form-item>
        <el-form-item :label="$t('sportsInfo_website_name')" prop="web_title">
          <el-input
            v-model="modelRef.web_title"
            :placeholder="$t('sportsInfo_website_name_tips')"
            :maxlength="16"
          />
        </el-form-item>
        <el-form-item :label="$t('sportsInfo_logo_set')" prop="logo">
          <Upload
            accept="image/png,image/jpeg"
            name="image"
            :limit="1"
            :action="basic['998Url'] + '/api/agentWeb/uploadImage'"
            v-model="modelRef.logo"
            :before-upload="beforeAvatarUpload"
            :tips="$t('sportsInfo_logo_set_tips')"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSubmit" :loading="submitLoading">
            {{ $t('team__submit') }}
          </el-button>
          <el-button @click="resetFields">
            {{ $t('team__reset') }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="preview margin_left_l">
      <div class="content">
        <img class="bg" src="@/assets/web/image/sportsInformation/wap_bg_2.png" alt="" />
        <div class="header">
          <img v-if="modelRef.logo[0]" class="logo" :src="modelRef.logo[0].url" alt="" />
          <div class="name">{{ modelRef.web_title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {reactive} from 'vue';
import {Options, Vue} from 'vue-class-component';

import {ElMessage, ElInput} from 'element-plus';
import {App, Server} from '@/services';
import format from '@/services/format';
import {ISportsInfoForm, SportsInformation, ISportsInfo} from '@/model/sportsInformation';
import Upload from '@/common/web/upload/index.vue';
import {basic} from '@/config';

@Options({
  components: {
    ElInput,
    Upload
  }
})
export default class BasicSet extends Vue {
  userinfo = App.getUserinfo();
  basic = basic;
  submitLoading = false;
  rules = {
    web_title: [
      {
        min: 4,
        max: 16,
        message: '字符长度只能是4~16位'
      }
    ]
  };
  agentInfo: any = {};
  isLoading = false;
  modelRef = reactive<ISportsInfoForm>({
    logo: [],
    web_title: ''
  });
  created() {
    this.getInfo();
  }
  beforeAvatarUpload(file: any) {
    const isJPG = file.type === 'image/jpeg' || 'image/png';
    const isLt = file.size < 1024 * 500;

    if (!isJPG) {
      ElMessage('上传图片只能是 JPG 和 PNG 格式!');
    }
    if (!isLt) {
      ElMessage('上传图片大小不能超过 500k!');
    }
    return isJPG && isLt;
  }
  async getInfo() {
    this.isLoading = true;
    const res = await Server.Web.SportsInformation.getWebSiteInfoByAgentId<ISportsInfo>({
      agent_id: this.userinfo?.userId
    });
    if (!(res instanceof Error)) {
      this.agentInfo = res;
      this.modelRef = {
        logo: res.logo
          ? [
              {
                uid: res.logo,
                name: res.logo,
                status: 'done',
                url: `${res.cdn}${res.logo}`
              }
            ]
          : [],
        web_title: res.web_title
      };
      this.isLoading = false;
    }
  }
  handleSubmit() {
    const form = this.$refs.formRef as any;
    const {modelRef} = this;
    this.submitLoading = true;
    form.validate(async (v: any) => {
      if (v) {
        const getFile = (item: any) => {
          return item ? item?.response?.data?.path ?? item?.name : '';
        };
        const res = await Server.Web.SportsInformation.setWebSiteInfo<any>({
          ...modelRef,
          logo: modelRef.logo.map(getFile)[0],
          domain: this.agentInfo.domain,
          id: this.agentInfo.id,
          agent_id: this.userinfo?.userId
        });
        if (!(res instanceof Error)) {
          ElMessage(this.$t('operate_success'));
          this.submitLoading = false;
        } else {
          ElMessage.error(res.message);
        }
      }
      this.submitLoading = false;
    });
  }
  resetFields() {
    this.modelRef = {
      logo: [],
      web_title: ''
    };
  }
  copy(val: string) {
    format.copyTextToClipboard(val);
    ElMessage(this.$t('ui_successful_copy'));
  }
}
</script>
<style lang="scss" scoped>
@import '../../../common/styles/element.scss';
.wrap {
  display: flex;
}
.formBox {
  flex: 1;
}
.preview {
  width: 300px;
  height: 533px;
  border-radius: 6px;
  background: #dcdfe6;
  overflow: hidden;
}
.content {
  width: 375px;
  transform-origin: top left;
  transform: scale(0.8);
  position: relative;

  .header {
    position: absolute;
    left: 11px;
    top: 51px;
    .logo {
      height: 31px;
      margin-right: 10px;
    }
    .name {
      position: relative;
      top: 10px;
      vertical-align: top;
      display: inline-block;
      font-size: 20px;
      color: #fff;
    }
  }
}
</style>
