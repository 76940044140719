<template>
  <div class="wrap">
    <el-form :model="modelRef" :rules="rules" ref="formRef" label-position="right">
      <el-divider content-position="left">{{ $t('sportsInfo_phone_ad') }}</el-divider>
      <el-form-item :label="$t('sportsInfo_target_site')" prop="ad_wap_href">
        <el-input v-model="modelRef.ad_wap_href" :placeholder="$t('ui_input')" />
      </el-form-item>
      <el-form-item :label="$t('sportsInfo_ad_pic')" prop="ad_wap_image">
        <Upload
          accept="image/png,image/jpeg,image/gif"
          name="image"
          :limit="1"
          :action="basic['998Url'] + '/api/agentWeb/uploadImage'"
          v-model="modelRef.ad_wap_image"
          :before-upload="beforeAvatarUpload"
          :tips="$t('sportsInfo_logo_set_tips')"
        />
      </el-form-item>
      <el-divider content-position="left">{{ $t('sportsInfo_pc_ad') }}</el-divider>
      <el-form-item :label="$t('sportsInfo_target_site')" prop="ad_pc_href">
        <el-input v-model="modelRef.ad_pc_href" :placeholder="$t('ui_input')" />
      </el-form-item>
      <el-form-item :label="$t('sportsInfo_ad_pic')" prop="ad_pc_image">
        <Upload
          accept="image/png,image/jpeg,image/gif"
          name="image"
          :limit="1"
          :action="basic['998Url'] + '/api/agentWeb/uploadImage'"
          v-model="modelRef.ad_pc_image"
          :before-upload="beforeAvatarUpload"
          :tips="$t('sportsInfo_logo_set_tips')"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSubmit" :loading="submitLoading">
          {{ $t('team__submit') }}
        </el-button>
        <el-button @click="resetFields">
          {{ $t('team__reset') }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script lang="ts">
import {reactive} from 'vue';
import {Options, Vue} from 'vue-class-component';

import {ElMessage, ElDivider, ElInput} from 'element-plus';
import {App, Server} from '@/services';
import format from '@/services/format';
import {ISportsInfoForm, SportsInformation, ISportsInfo} from '@/model/sportsInformation';
import Upload from '@/common/web/upload/index.vue';
import {basic} from '@/config';

@Options({
  components: {
    ElDivider,
    ElInput,
    Upload
  }
})
export default class BasicSet extends Vue {
  userinfo = App.getUserinfo();
  basic = basic;
  submitLoading = false;
  rules = {};
  agentInfo: any = {};
  isLoading = false;
  modelRef = reactive<ISportsInfoForm>({
    ad_pc_href: '',
    ad_pc_image: [],
    ad_wap_href: '',
    ad_wap_image: []
  });
  created() {
    this.getInfo();
  }
  beforeAvatarUpload(file: any) {
    const isJPG = file.type === 'image/jpeg' || 'image/png' || 'image/gif';
    const isLt = file.size < 1024 * 5000;

    if (!isJPG) {
      ElMessage('上传图片只能是 JPG 和 PNG 格式!');
    }
    if (!isLt) {
      ElMessage('上传图片大小不能超过 5000k!');
    }
    return isJPG && isLt;
  }
  async getInfo() {
    this.isLoading = true;
    const res = await Server.Web.SportsInformation.getWebSiteInfoByAgentId<ISportsInfo>({
      agent_id: this.userinfo?.userId
    });
    if (!(res instanceof Error)) {
      this.agentInfo = res;
      this.modelRef = {
        ad_pc_href: res.ad.pc?.href,
        ad_pc_image: res.ad.pc?.image
          ? [
              {
                uid: res.ad.pc.image,
                name: res.ad.pc.image,
                status: 'done',
                url: `${res.cdn}${res.ad.pc.image}`
              }
            ]
          : [],
        ad_wap_href: res.ad.wap?.href,
        ad_wap_image: res.ad.wap?.image
          ? [
              {
                uid: res.ad.wap.image,
                name: res.ad.wap.image,
                status: 'done',
                url: `${res.cdn}${res.ad.wap.image}`
              }
            ]
          : []
      };
      this.isLoading = false;
    }
  }
  handleSubmit() {
    const form = this.$refs.formRef as any;
    const {modelRef} = this;
    this.submitLoading = true;
    form.validate(async (v: any) => {
      if (v) {
        const getFile = (item: any) => {
          return item ? item?.response?.data?.path ?? item?.name : '';
        };
        const res = await Server.Web.SportsInformation.setWebSiteInfo<any>({
          logo: this.agentInfo.logo,
          domain: this.agentInfo.domain,
          web_title: this.agentInfo.web_title,
          id: this.agentInfo.id,
          agent_id: this.userinfo?.userId,
          ad: {
            pc: {
              href: modelRef.ad_pc_href,
              image: modelRef.ad_pc_image ? modelRef.ad_pc_image.map(getFile)[0] : null
            },
            wap: {
              href: modelRef.ad_wap_href,
              image: modelRef.ad_wap_image ? modelRef.ad_wap_image.map(getFile)[0] : null
            }
          }
        });
        if (!(res instanceof Error)) {
          ElMessage(this.$t('operate_success'));
          this.submitLoading = false;
        } else {
          ElMessage.error(res.message);
        }
      }
      this.submitLoading = false;
    });
  }
  resetFields() {
    this.modelRef = {
      ad_pc_href: '',
      ad_pc_image: [],
      ad_wap_href: '',
      ad_wap_image: []
    };
  }
  copy(val: string) {
    format.copyTextToClipboard(val);
    ElMessage(this.$t('ui_successful_copy'));
  }
}
</script>
<style lang="scss" scoped>
@import '../../../common/styles/element.scss';
.wrap {
  display: block;
  padding-right: 200px;
}
</style>
