<template>
  <div class="wrap">
    <div class="formBox">
      <el-form :model="modelRef" :rules="rules" ref="formRef" label-position="right">
        <el-form-item :label="$t('sportsInfo_template_select')">
          <div class="template">
            <div
              v-for="(item, index) in templateList"
              @click="clickTemplate(index)"
              :key="item.id"
              class="item"
              :class="{
                active: modelRef.templateActive === index
              }"
              :style="{backgroundImage: `url(${agentInfo.cdn}${item.name})`}"
            ></div>
          </div>
        </el-form-item>
        <el-form-item :label="$t('sportsInfo_custom_pic')">
          <Upload
            accept="image/png,image/jpeg"
            name="image"
            :limit="1"
            :action="basic['998Url'] + '/api/agentWeb/uploadImage'"
            v-model="modelRef.icon"
            :before-upload="beforeAvatarUpload"
            :tips="$t('sportsInfo_logo_set_tips')"
          />
        </el-form-item>
        <el-form-item :label="$t('float_window_link')" prop="float_window_line_text">
          <el-input v-model="modelRef.href" :placeholder="$t('ui_input')" />
        </el-form-item>
        <el-divider content-position="left">{{ $t('sportsInfo_line_set') }}</el-divider>
        <el-form-item :label="$t('sportsInfo_enable')">
          <el-switch
            active-color="#0f47e6"
            :active-value="1"
            :inactive-value="0"
            v-model="modelRef.float_window_line_status"
          ></el-switch>
        </el-form-item>
        <el-form-item :label="$t('sportsInfo_text')" prop="float_window_line_text">
          <el-input v-model="modelRef.float_window_line_text" :placeholder="$t('ui_input')" />
        </el-form-item>
        <el-form-item :label="$t('sportsInfo_target_address')">
          <el-input v-model="modelRef.float_window_line_href" :placeholder="$t('ui_input')" />
        </el-form-item>
        <el-form-item :label="$t('sportsInfo_btn_color')">
          <input
            type="color"
            class="colorSelect"
            v-model="modelRef.float_window_line_color"
            :placeholder="$t('ui_input')"
          />
        </el-form-item>
        <el-divider content-position="left">{{ $t('sportsInfo_fb_set') }}</el-divider>
        <el-form-item :label="$t('sportsInfo_enable')">
          <el-switch
            :active-value="1"
            :inactive-value="0"
            active-color="#0f47e6"
            v-model="modelRef.float_window_fb_status"
          ></el-switch>
        </el-form-item>
        <el-form-item :label="$t('sportsInfo_text')" prop="float_window_fb_text">
          <el-input v-model="modelRef.float_window_fb_text" :placeholder="$t('ui_input')" />
        </el-form-item>
        <el-form-item :label="$t('sportsInfo_target_address')">
          <el-input v-model="modelRef.float_window_fb_href" :placeholder="$t('ui_input')" />
        </el-form-item>
        <el-form-item :label="$t('sportsInfo_btn_color')">
          <input
            type="color"
            class="colorSelect"
            v-model="modelRef.float_window_fb_color"
            :placeholder="$t('ui_input')"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSubmit" :loading="submitLoading">
            {{ $t('team__submit') }}
          </el-button>
          <el-button @click="resetFields">
            {{ $t('team__reset') }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="preview margin_left_l">
      <div class="content">
        <img class="bg" src="@/assets/web/image/sportsInformation/wap_bg_1.png" alt="" />
        <div class="adBox">
          <div v-if="modelRef?.icon[0] || modelRef.templateActive >= 0" class="iconBox">
            <img
              class="icon"
              :src="
                (modelRef?.icon[0] && modelRef.icon[0].url) ||
                  agentInfo.cdn + templateList[modelRef.templateActive].name
              "
              alt=""
            />
            <img class="adorn" src="@/assets/web/image/sportsInformation/logo.png" alt="" />
          </div>
          <a
            v-if="modelRef.float_window_line_status"
            class="btn"
            :style="{backgroundColor: modelRef.float_window_line_color}"
            :href="modelRef.float_window_line_href"
            target="_blank"
          >
            <img class="icon" src="@/assets/web/image/sportsInformation/line.png" alt="" />
            <img class="adorn" src="@/assets/web/image/sportsInformation/adorn.png" alt="" />
            <div class="text">{{ modelRef.float_window_line_text }}</div>
          </a>
          <a
            v-if="modelRef.float_window_fb_status"
            class="btn"
            :style="{backgroundColor: modelRef.float_window_fb_color}"
            :href="modelRef.float_window_fb_href"
            target="_blank"
          >
            <img class="icon" src="@/assets/web/image/sportsInformation/facebook.png" alt="" />
            <img class="adorn" src="@/assets/web/image/sportsInformation/adorn.png" alt="" />
            <div class="text">{{ modelRef.float_window_fb_text }}</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {reactive} from 'vue';
import {Options, Vue} from 'vue-class-component';

import {ElMessage, ElSwitch, ElDivider, ElInput} from 'element-plus';
import {App, Server} from '@/services';
import {ISportsInfoForm, SportsInformation, ISportsInfo} from '@/model/sportsInformation';
import Upload from '@/common/web/upload/index.vue';
import {basic} from '@/config';

@Options({
  components: {
    ElInput,
    ElDivider,
    ElSwitch,
    Upload
  }
})
export default class BasicSet extends Vue {
  userinfo = App.getUserinfo();
  basic = basic;
  submitLoading = false;
  rules = {
    // float_window_fb_text: [{validator: SportsInformation.validateText, trigger: 'blur'}],
    // float_window_line_text: [{validator: SportsInformation.validateText, trigger: 'blur'}]
  };
  agentInfo: any = {};
  isLoading = false;
  modelRef = reactive<ISportsInfoForm>({
    templateActive: -1,
    web_title: '',
    icon: [],
    href: '',
    float_window_line_status: 0,
    float_window_line_text: '',
    float_window_line_color: '',
    float_window_line_href: '',
    float_window_fb_status: 0,
    float_window_fb_text: '',
    float_window_fb_color: '',
    float_window_fb_href: ''
  });
  templateList: any[] = [];
  created() {
    this.initPage();
  }
  async initPage() {
    this.isLoading = true;
    await this.getTempaleList();
    await this.getInfo();
    this.isLoading = false;
  }
  beforeAvatarUpload(file: any) {
    const isJPG = file.type === 'image/jpeg' || 'image/png';
    const isLt = file.size < 1024 * 500;

    if (!isJPG) {
      ElMessage('上传图片只能是 JPG 和 PNG 格式!');
    }
    if (!isLt) {
      ElMessage('上传图片大小不能超过 500k!');
    }
    return isJPG && isLt;
  }
  async getTempaleList() {
    const res = await Server.Web.SportsInformation.getAllImageList<any>();
    if (!(res instanceof Error)) {
      this.templateList = res.map((item: any) => {
        return {
          ...item,
          name: `/${item.name}`
        };
      });
    }
  }
  async getInfo() {
    const res = await Server.Web.SportsInformation.getWebSiteInfoByAgentId<ISportsInfo>({
      agent_id: this.userinfo?.userId
    });
    if (!(res instanceof Error)) {
      this.agentInfo = res;
      const templateActive = res.float_window?.icon
        ? this.templateList.findIndex(item => item.name === res.float_window.icon)
        : -1;
      this.modelRef = {
        icon:
          res.float_window?.icon && templateActive < 0
            ? [
                {
                  uid: res.float_window.icon,
                  name: res.float_window.icon,
                  status: 'done',
                  url: `${res.cdn}${res.float_window.icon}`
                }
              ]
            : [],
        templateActive,
        href: res.float_window?.href,
        float_window_line_status: res.float_window?.line?.status,
        float_window_line_text: res.float_window?.line?.text,
        float_window_line_color: res.float_window?.line?.color,
        float_window_line_href: res.float_window?.line?.href,
        float_window_fb_status: res.float_window?.fb?.status,
        float_window_fb_text: res.float_window?.fb?.text,
        float_window_fb_color: res.float_window?.fb?.color,
        float_window_fb_href: res.float_window?.fb?.href
      };
    }
  }
  handleSubmit() {
    const form = this.$refs.formRef as any;
    const {modelRef} = this;
    this.submitLoading = true;
    form.validate(async (v: any) => {
      if (v) {
        const getFile = (item: any) => {
          return item ? item?.response?.data?.path ?? item?.name : '';
        };
        let icon = undefined;
        if (modelRef.icon && modelRef.icon.length) {
          icon = modelRef.icon.map(getFile)[0];
        } else if (modelRef.templateActive || modelRef.templateActive === 0) {
          icon = this.templateList[modelRef.templateActive].name;
        }
        const res = await Server.Web.SportsInformation.setWebSiteInfo<any>({
          logo: this.agentInfo.logo,
          domain: this.agentInfo.domain,
          web_title: this.agentInfo.web_title,
          id: this.agentInfo.id,
          agent_id: this.userinfo?.userId,
          float_window: {
            icon,
            href: modelRef.href,
            line: {
              status: modelRef.float_window_line_status,
              text: modelRef.float_window_line_text,
              color: modelRef.float_window_line_color,
              href: modelRef.float_window_line_href
            },
            fb: {
              status: modelRef.float_window_fb_status,
              text: modelRef.float_window_fb_text,
              color: modelRef.float_window_fb_color,
              href: modelRef.float_window_fb_href
            }
          }
        });
        if (!(res instanceof Error)) {
          ElMessage(this.$t('operate_success'));
          this.submitLoading = false;
        } else {
          ElMessage.error(res.message);
        }
      }
      this.submitLoading = false;
    });
  }
  resetFields() {
    this.modelRef = {
      templateActive: -1,
      web_title: '',
      icon: [],
      href: '',
      float_window_line_status: 0,
      float_window_line_text: '',
      float_window_line_color: '',
      float_window_line_href: '',
      float_window_fb_status: 0,
      float_window_fb_text: '',
      float_window_fb_color: '',
      float_window_fb_href: ''
    };
  }
  clickTemplate(index: number) {
    this.modelRef.templateActive = index;
  }
}
</script>
<style lang="scss" scoped>
@import '../../../common/styles/element.scss';
.wrap {
  display: flex;
}
.formBox {
  flex: 1;
}
.preview {
  width: 300px;
  height: 533px;
  border-radius: 6px;
  background: #dcdfe6;
  overflow: hidden;
}
.content {
  position: relative;
  width: 375px;
  transform-origin: top left;
  transform: scale(0.8);

  .adBox {
    position: absolute;
    right: 10px;
    bottom: 300px;
    width: 100px;
    .iconBox {
      width: 100%;
      position: relative;
      .icon {
        width: 100%;
      }
      .adorn {
        position: absolute;
        left: 0;
        bottom: -28px;
        width: 100%;
        height: 100px;
      }
    }
    .btn {
      position: relative;
      width: 100%;
      min-height: 30px;
      background-color: #000;
      border-radius: 6px;
      display: flex;
      align-items: center;
      padding: 4px 6px;
      cursor: pointer;
      .icon {
        margin-right: 5px;
        width: 14px;
        height: 14px;
      }
      .adorn {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
      }
      .text {
        font-size: 14px;
        color: #fff5aa;
        line-height: 20px;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
        word-break: break-all;
      }
    }
  }
}
.colorSelect {
  border: none;
  width: 100px;
  height: 40px;
}
.template {
  display: inline-block;
  width: 100%;
  .item {
    margin-right: 10px;
    border: 2px solid #dcdfe6;
    display: inline-block;
    width: 80px;
    height: 80px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 2px;
    cursor: pointer;
    &.active {
      border-color: #0f47e6;
    }
  }
}
</style>
